import { useState, useCallback, useEffect } from 'react';
import { User } from '../entities';
import { ChatChannel, ChatMessage, ChatMessageHeaders, ChatMessageReplyProps, StateCB } from '../entities/chat';
import Pusher from 'pusher-js';

import TurndownService from 'turndown';

import { parse } from 'query-string';
import { ALL_CHAT_INFO, CHAT_ACTION_MESSAGE, INITIAL_AMOUNT_OF_LOADED_MESSAGES, POST_MESSAGE_NOTIFICATION } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from 'configcat-react';
import { useChatApi } from '../../api/useChatApi';

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

export type Result = {
  chatChannels: ChatChannel[];
  chatWhereUserMember: ChatChannel[];
  handleChannel: (channelId: string, isRefresh?: boolean, isRedirect?: boolean, endpoint?: string) => void;
  activeChannelId: string;
  messages: any;
  retrieveMessages: (channelId: string, part?: number, hardLoad?: boolean) => Promise<void>;
  replyTo?: ChatMessageReplyProps;
  setReplyTo: (message?: ChatMessageReplyProps | StateCB) => void;
  users: User[];
  loadUsers: () => void;
};

const APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY || '',
  APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const pusher = new Pusher(APP_KEY, {
  cluster: APP_CLUSTER + '',
});

let channelSubscriber: any;
const refreshChannels: any = {};

export function useChat(controlFunctions: any, user: User | undefined, searchParams: string): Result {
  const navigate = useNavigate();

  const { value: chatEnabled } = useFeatureFlag('chat', false, { identifier: user?.email! });

  const { getChannels, getMessages, getUsers } = useChatApi();

  const [messages, setMessages] = useState<any>({});
  const [replyTo, setReplyTo] = useState<ChatMessageReplyProps>();
  const [activeChannelId, setActiveChannelId] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const [chatChannels, setChannels] = useState<ChatChannel[]>([]);
  const [chatWhereUserMember, setChatWhereUserMember] = useState<ChatChannel[]>([]);

  const [currentUrlParams] = useState<{ [key: string]: string | null }>(parse(searchParams) as { [key: string]: string | null });

  const chatId = (currentUrlParams['chat-id'] || 'non-chat') + '';

  const mutateChatChannels = useCallback(
    async (chatEnabled: boolean) => {
      const { channels }: { channels: ChatChannel[] } = chatEnabled ? (await getChannels(searchParams)).data : { channels: [] };

      window.parent.postMessage(
        {
          type: ALL_CHAT_INFO,
          data: channels.filter(channel => channel.chatId === chatId),
        },
        '*',
      );

      setChatWhereUserMember(channels);
      setChannels(channels);
    },
    [getChannels, chatId, searchParams],
  );

  useEffect(() => {
    if (user) {
      mutateChatChannels(chatEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatEnabled, user, mutateChatChannels]);

  const loadUsers = useCallback(async () => {
    if (chatEnabled) {
      const result = (await getUsers(chatId)).data?.data as User[];
      setUsers(result);
    }
  }, [getUsers, chatId, chatEnabled]);

  const cancelSubscription = (channelId: string) => {
    channelSubscriber.unbind();
    pusher.unsubscribe(channelId);
  };

  const retrieveMessages = async (channelId: string, part = INITIAL_AMOUNT_OF_LOADED_MESSAGES, hardLoad?: boolean) => {
    if (hardLoad || !messages[channelId]?.length || refreshChannels[channelId]) {
      const chatId = (currentUrlParams['chat-id'] || 'non-chat') + '';
      //axiosChatInstance.defaults.headers.common['ChatId'] = chatId;
      // create deep copy of object
      const newMessages = JSON.parse(JSON.stringify(messages));
      newMessages[channelId] = (await getMessages(channelId, chatId, part)).data?.data;
      setMessages({ ...newMessages });
      delete refreshChannels[channelId];
    }
  };

  const pushMessage = (message: ChatMessage) => {
    if (!message.channel?.id) return;
    setMessages(messages => {
      if (!messages[message.channel.id]?.length) {
        const newMessages = JSON.parse(JSON.stringify(messages));
        newMessages[message.channel.id] = [message];
        return { ...newMessages };
      } else {
        if (message.replyTo?.id) {
          // create deep copy of object
          const newMessages = JSON.parse(JSON.stringify(messages));
          newMessages[message.channel.id].find((m: ChatMessage) => m.id === message.replyTo.id)?.thread?.unshift({ ...message, shouldDisplay: true });

          return { ...newMessages };
        } else {
          // create deep copy of object
          const newMessages = JSON.parse(JSON.stringify(messages));
          newMessages[message.channel.id].unshift(message);
          return { ...newMessages };
        }
      }
    });
  };

  const subscribeChannel = (channelId: string) => {
    const chatId = (currentUrlParams['chat-id'] || 'non-chat') + '';
    const pusherChannelId = channelId + '-' + chatId;
    if (channelSubscriber?.name === pusherChannelId) return;

    if (channelSubscriber) cancelSubscription(pusherChannelId);
    channelSubscriber = pusher.subscribe(pusherChannelId);
    channelSubscriber.bind('chat:new-message-' + process.env.REACT_APP_CHAT_ID, (message: { messageData: ChatMessage; visibleFor: string }) => {
      if (message.visibleFor) {
        if (message.visibleFor === user?.id) {
          pushMessage(message.messageData);
        }
      } else {
        pushMessage(message.messageData);
      }
    });
  };

  const handleChannel = async (channelId: string, isRefresh?: boolean, isRedirect?: boolean, endpoint = 'chat') => {
    if (isRefresh) refreshChannels[channelId] = true;
    setActiveChannelId(channelId);
    if (channelId !== '') {
      channelSubscriber?.unbind();
      channelSubscriber = null;
      subscribeChannel(channelId);
      await retrieveMessages(channelId);
      controlFunctions.forceUpdate();

      if (isRedirect) {
        navigate(`/${endpoint}/${channelId}`);
      }
    }
  };

  if (chatEnabled) {
    const baseEventsSubscriber = pusher.subscribe('chat-base-events-' + process.env.REACT_APP_CHAT_ID);

    baseEventsSubscriber.unbind();

    baseEventsSubscriber.bind('chat:channel-changed-' + process.env.REACT_APP_CHAT_ID, (channelId: string) => {
      mutateChatChannels(chatEnabled);
    });

    baseEventsSubscriber.bind('chat:new-channel-created-' + process.env.REACT_APP_CHAT_ID, (data: { user: string; channelId: string }) => {
      mutateChatChannels(chatEnabled);
    });

    baseEventsSubscriber.bind(chatId + ':new-channel-member-' + process.env.REACT_APP_CHAT_ID, () => {
      loadUsers();
    });

    baseEventsSubscriber.bind('chat:channel-renamed-' + process.env.REACT_APP_CHAT_ID, (channel: ChatChannel) => {
      const chatChannel = chatChannels.find(c => c.id === channel.id);
      if (chatChannel) {
        chatChannel.isNameEdited = true;
        chatChannel.name = channel.name;
        controlFunctions.forceUpdate();
      }
    });

    baseEventsSubscriber.bind('chat:new-message-in-channel-' + process.env.REACT_APP_CHAT_ID, (message: ChatMessageHeaders) => {
      refreshChannels[message.channel.id] = true;
      if (message.author.id !== user?.id && !message.isServiceMessage && (!message.visibleFor || message.visibleFor === user?.id)) {
        const chatChannel = chatChannels.find(c => c.id === message.channel.id);
        if (chatChannel && !message.dontIncreaseUnread) {
          if (!message.isServiceMessage) {
            chatChannel.unread++;
          }
          let isYouMentioned = message.mentions && user && message.mentions.filter(el => el.includes(user.id));
          if (isYouMentioned?.length && !message.isServiceMessage)
            chatChannel.unreadMentions = chatChannel.unreadMentions ? chatChannel.unreadMentions + 1 : 1;
          controlFunctions.forceUpdate();
        }
        if (chatChannel) {
          let isYouMentioned = message.mentions && user && message.mentions.filter(el => el.includes(user.id));

          if (activeChannelId !== message.channel.id) {
            const turndownService = new TurndownService();
            const data = turndownService.turndown(message.message.text);
            message.message.text = data;
            window.parent.postMessage({ type: POST_MESSAGE_NOTIFICATION, data: { ...message, isYouMentioned } }, '*');
          }

          window.parent.postMessage({ type: CHAT_ACTION_MESSAGE, data: chatChannel }, '*');
        }
      }
    });
  }

  return {
    chatChannels,
    chatWhereUserMember,
    handleChannel,
    activeChannelId,
    messages,
    retrieveMessages,
    replyTo,
    setReplyTo,
    users,
    loadUsers,
  };
}
