import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { PageHeader, Button, Spin, Popconfirm, Tabs, Row, Col, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Layout from '../../layouts/authorised/Authorised';
import { useNavigate, useParams } from 'react-router-dom';
import { useClient } from '../../dal';
import AddProjectForm from '../../components/project/AddProjectForm';
import ClientReport from '../../components/clients/ClientReport';
import ProjectsTable from '../../components/project/ProjectsTable';
import { useDebouncedCallback } from 'use-debounce';
import Links from '../../components/shared/Links';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ClientPermissions } from '../../common/usePermissions/permissions';
import AddEditAccountForm from '../../components/clients/AddEditAccountForm';
import ClientAccounts from '../../components/clients/ClientAccounts';
import ClientBillingAddress from '../../components/clients/ClientBillingAddress';

const { Paragraph } = Typography;

type UrlParams = {
  id: string;
};

const Client = () => {
  const { id } = useParams<UrlParams>();
  const navigate = useNavigate();
  const { client, mutate } = useClient(id);
  const [newProjectDrawerOpen, setNewProjectDrawerOpen] = useState<boolean>(false);
  const [newAccountDrawerOpen, setNewAccountDrawerOpen] = useState<boolean>(false);
  const [clientName, setClientName] = useState<string>('');
  const { hasPermission } = usePermissions();

  const onDelete = async () => {
    await axios.delete(`/clients/${id}`);
    navigate('/clients');
  };

  const saveClient = useCallback(
    async values => {
      await axios.put(`/clients/${id}`, values);
      await mutate(null, true);
    },
    [id, mutate],
  );

  useEffect(() => {
    if (client && client.name) setClientName(client.name);
  }, [client]);

  const debouncedClientName = useDebouncedCallback(name => {
    setClientName(name);
    saveClient({ name });
  }, 1000);

  if (!client)
    return (
      <Layout>
        <Spin />
      </Layout>
    );

  return (
    <Layout overrideBackgroundContainer>
      <PageHeader
        title={
          <Paragraph
            editable={
              hasPermission(ClientPermissions.CLIENT_WRITE)
                ? { onChange: debouncedClientName, triggerType: ['icon', 'text'], enterIcon: null }
                : false
            }
            style={{ display: 'inline-block', margin: '0 15px' }}
          >
            {clientName}
          </Paragraph>
        }
        subTitle={client.autoCode}
        onBack={() => navigate(-1)}
        extra={
          <>
            {hasPermission(ClientPermissions.CLIENT_DELETE) && (
              <Popconfirm title="Are you sure to delete this client?" onConfirm={onDelete} okText="Yes" cancelText="No">
                <Button danger>Delete client</Button>
              </Popconfirm>
            )}
            {hasPermission(ClientPermissions.CLIENT_ACCOUNT_WRITE) && (
              <Button icon={<PlusOutlined />} type="primary" onClick={() => setNewAccountDrawerOpen(true)}>
                Create client account
              </Button>
            )}
            <Button icon={<PlusOutlined />} type="primary" onClick={() => setNewProjectDrawerOpen(true)}>
              Add project
            </Button>
          </>
        }
      />

      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <div className="custom-container">
            <ClientBillingAddress billingAddress={client.billingAddress} onSave={billingAddress => saveClient({ billingAddress })} />
          </div>
        </Col>

        <Col span={12}>
          <div className="custom-container">
            <Links links={client.links} onSubmit={links => saveClient({ links })} />
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <div className="custom-container">
            <Tabs defaultActiveKey="projects">
              <Tabs.TabPane tab="Projects" key="projects">
                <ProjectsTable projects={client.projects} filterKey="clientsproject" />
              </Tabs.TabPane>
              {hasPermission(ClientPermissions.CLIENT_REPORT_READ) && (
                <Tabs.TabPane tab="Report" key="report">
                  <ClientReport client={client} />
                </Tabs.TabPane>
              )}
              {hasPermission(ClientPermissions.CLIENT_ACCOUNT_READ) && (
                <Tabs.TabPane tab="Accounts" key="accounts">
                  <ClientAccounts clientId={client.id} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </div>
        </Col>
      </Row>

      {newProjectDrawerOpen && <AddProjectForm modalOpen={newProjectDrawerOpen} setModalOpen={setNewProjectDrawerOpen} client={id} />}
      {newAccountDrawerOpen && (
        <AddEditAccountForm modalOpen={newAccountDrawerOpen} closeModal={() => setNewAccountDrawerOpen(false)} clientId={id} />
      )}
    </Layout>
  );
};

export default Client;
