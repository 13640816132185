import { useEffect, useState, useCallback, createRef } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { parse } from 'query-string';
import { useProjects } from '../../../dal';
import type { Project as IProject } from '../../../entities';
import ChatArea from '../../components/chat/chatArea';
import { Result, UserWithPermissions, useChat, useChatSession } from '../../dal';
import Layout from '../../layouts/chat/Chat';
import { DashboardPages, ISelectedPage } from './types/types';
import Project from '../../../pages/projects/[id]';
import DashboardSidebar from './components/DashboardSidebar';
import './index.scss';
import { NewChannelData, useChatApi } from '../../../api/useChatApi';

interface IProps {
  session?: UserWithPermissions;
  path: string;
  projects: IProject[];
  chatData: Result;
  searchParams: TSearchParams;
  isError: boolean;
  isLoading: boolean;
}

const controlFunctions: any = {};

const Dashboard = ({ session, projects, chatData, searchParams, isError, isLoading }: IProps) => {
  const { id: idFromParams } = useParams<{ id: string }>();
  const [, updateState] = useState({});
  const chatApi = useChatApi();

  controlFunctions.forceUpdate = useCallback(() => updateState({}), []);

  const chatId = searchParams['chat-id'] || 'non-chat';
  const isAllowToCommunicate = searchParams['allow-comment'];

  const [selectedPage, setSelectedPage] = useState<ISelectedPage<any>>({ type: DashboardPages.INITIAL, data: null });
  const [isCreatingNewChannel, setIsCreatingNewChannel] = useState(false);
  const [newChannelMembers, setNewChannelMembers] = useState<string[]>([]);
  const [channelListOpen, setChannelListOpen] = useState<boolean>(true);

  const { 
    chatChannels, 
    activeChannelId, 
    handleChannel, 
    messages, 
    replyTo, 
    retrieveMessages, 
    setReplyTo, 
    users, 
    loadUsers, 
    chatWhereUserMember
  } = chatData;

  const closeChannelList = () => {
    setNewChannelMembers([]);
    setIsCreatingNewChannel(false);
    setChannelListOpen(false);
  };

  const handleChannelListOpen = () => setChannelListOpen(!channelListOpen);

  const createNewChannel = async (values: NewChannelData) => {
    const channelName = values.name || newChannelMembers[0].split('/')[0] + ' and other';
    const result = await chatApi.createChannel(chatId, channelName, values);

    if (result.data?.data?.id) {
      chatChannels.push({ id: result.data.data.id, name: result.data.data.name, unread: 0, manualUnread: 0, users: result.data.users });
      setTimeout(() => {
        handleChannel(result.data.data.id, false, true);
      }, 0);
      // formNewChannel.resetFields();
    }
  };

  const renameChannel = async (name: string) => {
    const channelData = chatChannels.find(chnl => chnl.id === activeChannelId);
    if (
      name.length > 0 &&
      (channelData?.isNameEdited ||
        name !== channelData?.users.reduce((pV, cV) => (cV.user.id !== session?.id ? `${pV ? pV + ', ' : ''}${cV.user.name}` : pV), ''))
    ) {
      const result = await chatApi.renameChannel(activeChannelId, name);
      if (result.data?.data?.id) {
        chatChannels[chatChannels.findIndex(c => c.id === activeChannelId)].name = name;
        controlFunctions.forceUpdate();
      }
    }
  };

  const DashboardSidebarRef = createRef<HTMLDivElement>();
  const DashboardMainContainerRef = createRef<HTMLDivElement>();
  const CustomDividerRef = createRef<HTMLDivElement>();

  const handleSideBarWidth = useCallback(() => {
    const onMouseMove = (e: MouseEvent) => {
      const [dashboardSidebar, dashboardMainContainer] = [DashboardSidebarRef.current, DashboardMainContainerRef.current];
      if (dashboardSidebar && dashboardMainContainer) {
        document.body.style.userSelect = 'none';
        if (e.pageX > 288 && e.pageX < 600) {
          document.body.className = e.pageX - 55 + '';
          dashboardSidebar.style.minWidth = `${e.pageX - 55}px`;
          dashboardSidebar.style.width = `${e.pageX - 55}px`;
          dashboardSidebar.style.display = 'block';
        }
      }
    };
    const handleMouseUp = () => {
      document.body.style.userSelect = '';
      localStorage.setItem('left-bar-width', document.body.className);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    const onMouseDown = () => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };
    const notFormatedWidth = localStorage.getItem('left-bar-width');
    const width = notFormatedWidth !== null && notFormatedWidth.length > 0 ? Number(localStorage.getItem('left-bar-width')) : 250;
    const [dashboardSidebar, dashboardMainContainer] = [DashboardSidebarRef.current, DashboardMainContainerRef.current];
    if (width >= 0 && dashboardSidebar && dashboardMainContainer) {
      if (width > 220 && width < 600) {
        dashboardSidebar.style.minWidth = width + 'px';
        dashboardSidebar.style.width = width + 'px';
      }
    }
    const divider = CustomDividerRef.current;
    if (divider) {
      divider.addEventListener('mousedown', onMouseDown);
    }
  }, [DashboardSidebarRef, DashboardMainContainerRef, CustomDividerRef]);

  useEffect(() => {
    handleSideBarWidth();
  }, [handleSideBarWidth]);

  useEffect(() => {
    if (idFromParams) {
      setSelectedPage({
        type: DashboardPages.PROJECT,
        data: {
          id: idFromParams,
        } as IProject,
      });
    }
  }, [idFromParams]);

  const handleSelectedPageChange = (page: ISelectedPage<any>) => {
    setSelectedPage(page);
  };

  return (
    <Layout>
      <section className="dashboard-container flex w-full">
        <DashboardSidebar
          session={session}
          chatData={chatData}
          projects={projects}
          selectedPage={selectedPage}
          isCreatingNewChannel={isCreatingNewChannel}
          isError={isError}
          isLoading={isLoading}
          createNewChannel={createNewChannel}
          onSelectedPageChange={handleSelectedPageChange}
          onCreatingNewChannelChange={setIsCreatingNewChannel}
          onCloseChannelList={closeChannelList}
          ref={DashboardSidebarRef}
        />

        <div id="custom-divider-new" className="custom-divider" ref={CustomDividerRef}></div>
        <main id="dashboard-main-container" className="page-content flex-auto" ref={DashboardMainContainerRef}>
          {selectedPage.type === DashboardPages.CREATE ? (
            <ChatArea
              idToCheck="create"
              type="nothing"
              isCreatingNewChannel={true}
              handleChannelListOpen={handleChannelListOpen}
              renameChannel={renameChannel}
              channel={null}
              messages={messages}
              session={session}
              chatChannels={chatChannels}
              activeChannelId={activeChannelId}
              users={users}
              replyTo={replyTo}
              setReplyTo={setReplyTo}
              retrieveMessages={retrieveMessages}
              isAllowToCommunicate={isAllowToCommunicate}
              loadUsers={loadUsers}
              chatId={chatId}
              searchParams={searchParams}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
              handleChannel={handleChannel}
              setIsCreatingNewChannel={setIsCreatingNewChannel}
              controlFunctions={controlFunctions}
              chatWhereUserMember={chatWhereUserMember}
            />
          ) : null}
          {selectedPage.type === DashboardPages.PROJECT ? <Project id={selectedPage.data.id} chatData={chatData} /> : null}
          {selectedPage.type === DashboardPages.CHAT && selectedPage.data.type ? (
            <ChatArea
              idToCheck={selectedPage.data.id}
              type={selectedPage.data.type}
              isCreatingNewChannel={isCreatingNewChannel}
              handleChannelListOpen={handleChannelListOpen}
              renameChannel={renameChannel}
              channel={selectedPage.data}
              messages={messages}
              session={session}
              chatChannels={chatChannels}
              activeChannelId={activeChannelId}
              users={users}
              replyTo={replyTo}
              isAbleToRemoveUser={selectedPage.data.users.length > 2 ? true : false}
              setReplyTo={setReplyTo}
              retrieveMessages={retrieveMessages}
              isAllowToCommunicate={isAllowToCommunicate}
              loadUsers={loadUsers}
              chatId={chatId}
              searchParams={searchParams}
              newChannelMembers={newChannelMembers}
              onAddUserToChannel={setNewChannelMembers}
              setIsCreatingNewChannel={setIsCreatingNewChannel}
              handleChannel={handleChannel}
              createNewChannel={createNewChannel}
              controlFunctions={controlFunctions}
              chatWhereUserMember={chatWhereUserMember}
            />
          ) : null}
        </main>
      </section>
    </Layout>
  );
};

interface IChatProps {
  data: string;
}

type TSearchParams = { [key: string]: string | null };

const Chat = (props: IChatProps) => {
  const { data } = props;

  const searchParams = parse(data) as TSearchParams;
  const path = data.substring(1);
  const { session, isError, isLoading } = useChatSession(path);

  const { projects } = useProjects();

  const chatData = useChat(controlFunctions, session, path);

  if (isLoading) {
    return (
      <Layout>
        <Spin />
      </Layout>
    );
  }

  return (
    <Dashboard
      isError={isError}
      isLoading={isLoading}
      projects={projects}
      session={session}
      path={path}
      chatData={chatData}
      searchParams={searchParams}
    />
  );
};

export default Chat;
