import { Avatar, Card, List, Space, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { useUsers } from '../../dal';
import { User } from '../../entities';
import Layout from '../../layouts/authorised/Authorised';
import { useState } from 'react';

const Users = () => {
  const [activeOnly, setActiveOnly] = useState(true);
  const { users } = useUsers({ activeOnly });

  return (
    <Layout>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Switch
          checkedChildren="Only active users"
          unCheckedChildren="All users"
          checked={activeOnly}
          onChange={(checked: boolean) => setActiveOnly(checked)}
        />
        <List
          dataSource={users}
          loading={!users}
          itemLayout="vertical"
          grid={{ gutter: 32, column: 3 }}
          renderItem={(item: User) => (
            <Link to={`/users/${item.id}`}>
              <List.Item key={item.id}>
                <Card hoverable>
                  <List.Item.Meta
                    avatar={<Avatar src={item.picture} size={64} />}
                    title={item.name}
                    description={
                      <>
                        {item.email}
                        <br />
                        {item.title}
                      </>
                    }
                  />
                </Card>
              </List.Item>
            </Link>
          )}
        />
      </Space>
    </Layout>
  );
};

export default Users;
