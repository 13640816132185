import React from 'react';
import { parse } from 'query-string';
import Chat from './pages/dashboard/dashboard';
import ChannelChat from './pages/chat/channel-chat';
import { URL_PARAMS, VIEW_TYPES } from './constants';
import './index.scss';
import { Result } from './dal';

interface IProps {
  data: string;
  chatData?: Result;
}

const ChatApp: React.FC<IProps> = ({ data, chatData }) => {
  // const { createNotification, contextHolder } = useNotify();
  const searchParams = parse(data) as { [key: string]: string | null },
    chatType = searchParams[URL_PARAMS.VIEW];

  return (
    <div id="chat-container">
      {chatType === VIEW_TYPES.DIRECT ? <Chat data={data} /> : <ChannelChat idToCheck={searchParams} data={data} upChatData={chatData} />}
    </div>
  );
};

export default ChatApp;
