import { unpackRules } from '@casl/ability/extra';
import { AbilityClass, AnyMongoAbility, PureAbility, createMongoAbility } from '@casl/ability';
import { useSession } from '../../dal';
import { PermissionsType } from './permissions';

type Actions = 'create' | 'view' | 'update' | 'delete';
type Subjects = 'Invoice' ;

export type AppAbility = PureAbility<[Actions, Subjects]>;
export const AppPureAbility = PureAbility as AbilityClass<AppAbility>;

export function usePermissions(): { 
    hasPermission: (p: PermissionsType) => boolean; 
    permissions: PermissionsType[]; 
    ability: AnyMongoAbility; 
    rules: any } {
  const { session } = useSession();
  const permissions = session?.permissions || [];
  let rules = [];
  let ability : AnyMongoAbility = new PureAbility();

  if (session) {
    rules = unpackRules(session?.accessRights);
    ability = createMongoAbility(rules);
  }

  const hasPermission = (permission: PermissionsType) => {
    return permissions.includes(permission);
  };

  return { permissions, hasPermission, ability, rules };
}
