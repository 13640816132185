import useSWR from 'swr';
import { FetchResult } from '../entities';
import moment from 'moment';

export type TimesheetSummary = {
  key: string;
  userId: number;
  category: string;
  ratePerHour: number;
  rateType: string;
  totalMinutes: number;
  totalCost: number;
  phaseName: string;
  userName: string;
  billableHours?: number;
  billableRate?: number;
  billableTotal?: number;
  isGroup?: boolean;
};

type Result = FetchResult & { timesheets: TimesheetSummary[] };

export function useProjectsExtendedTimesheetSummary(id?: string, fromDate?: moment.Moment, toDate?: moment.Moment): Result {
  let queryString = fromDate && toDate ? `?from=${fromDate?.format('YYYY-MM-DD')}&to=${toDate?.format('YYYY-MM-DD')}` : '';
  const { data, error, mutate } = useSWR(id ? `/projects/${id}/timesheet-summary-extended${queryString}` : null);

  const timesheets = data?.data || [];

  return {
    timesheets: timesheets.map(t => ({
      ...t,
      key: `${t.phaseName || ''}_${t.category}_${t.userId}_${t.rateType}_${t.ratePerHour}`,
      totalMinutes: Number(t.totalMinutes),
      totalCost: Number(t.totalCost),
    })),
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
