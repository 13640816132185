import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Button, Form, Input, Popconfirm, Row, Col, Spin, message, DatePicker, Table, Collapse, Dropdown, MenuProps, Switch, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Column from 'antd/lib/table/Column';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import TextArea from 'antd/lib/input/TextArea';
import { RightOutlined, DownOutlined, EllipsisOutlined, DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import MoneyInput from '../shared/MoneyInput';
import { useProjectFinance } from '../../dal';
import { datePickerFormat } from '../../constants';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { Payment } from '../../entities';
import { displayNumberFromHours, formatCurrency } from '../../common/utils';

import './ProjectFinance.css';
import InvoiceCreator from './InvoiceCreator';
import { convertPaymentsDateRange, deepMerge, getInvoiceStatus } from '../../utils';
import InvoiceTable from './InvoiceTable';

type Props = {
  projectId: string;
};

const ProjectFinances = ({ projectId }: Props) => {
  const { finance, mutate: mutateFinance } = useProjectFinance(projectId);
  const { ability } = usePermissions();
  const [form] = useForm();
  const [estimatedPayDates, setEstimatedPayDates] = useState<(moment.Moment | Date | null | undefined)[]>([]);
  const [payDateFullness, setPayDateFullness] = useState<boolean[]>([]);
  const [isActiveInvoices, setIsActiveInvoices] = useState<boolean[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editingInvoiceIndex, setEditingInvoiceIndex] = useState<number | null>(null);
  const [billableRate, setBillableRate] = useState<number | string | null>(null);

  useEffect(() => {
    if (finance) {
      setBillableRate(finance.billableRate);
    }
  }, []);

  const handleBillableRateChange = (value: number | string | null) => {
    setBillableRate(value);
  };

  const onFinish = useCallback(
    async (values: any) => {
      const resp = await axios.put(`/projects/${projectId}/finance`, { ...values, billableRate });
      message.success('Data saved');
      return resp;
    },
    [billableRate, projectId],
  );

  const updateTotalAfterPaymentChange = useCallback(
    (_: any, { payments }: { payments: Payment[] }) => {
      payments.forEach(p => {
        if (p?.details) {
          let invoiceTotal = 0;
          let actualTotal = 0;

          p.details.forEach(d => {
            const billableHours = typeof d.billableHours === 'number' ? d.billableHours : displayNumberFromHours(d.billableHours);

            d.billableTotal = +(billableHours * d.billableRate).toFixed(2);

            invoiceTotal += d.billableTotal;
            actualTotal += +d.totalCost;
          });

          p.value = Math.round(invoiceTotal * 100) / 100;
          p.markupPercentage = ((1 - actualTotal / invoiceTotal) * 100).toFixed(2);
        }
      });

      form.setFieldsValue({ payments });

      const actualEstimatedPayDates = payments.map(payment => payment?.estimatedPayDate);
      const paidFullness = payments.map(payment => !!payment?.paidDate);
      const activeInvoiceFullness = payments.map(payment => !!payment?.isActive);

      setEstimatedPayDates(actualEstimatedPayDates);
      setPayDateFullness(paidFullness);
      setIsActiveInvoices(activeInvoiceFullness);
    },
    [form],
  );

  const submit = useCallback(async () => {
    await onFinish({
      ...form.getFieldsValue(),
    });

    await mutateFinance();
  }, [form, mutateFinance, onFinish]);

  const addInvoice = useCallback(
    invoiceData => {
      const newInvoiceData = {
        value: invoiceData.value,
        billableRate: invoiceData.billableRate,
        details: invoiceData.details,
        dateRange: invoiceData.dateRange,
        summaryBillableHours: invoiceData.summaryBillableHours,
        summaryBillableSpent: invoiceData.summaryBillableSpent,
        summaryRealSpent: invoiceData.summaryRealSpent,
        summaryTotalMinutes: invoiceData.summaryTotalMinutes,
        markupPercentage: invoiceData.markupPercentage,
        description: `Invoice date range: ${invoiceData.dateRange[0]?.format('YYYY-MM-DD')} - ${invoiceData.dateRange[1]?.format('YYYY-MM-DD')}`,
      };

      const currentPayments = form.getFieldValue('payments') || [];
      if (editingInvoiceIndex !== null) {
        currentPayments[editingInvoiceIndex] = deepMerge(currentPayments[editingInvoiceIndex], newInvoiceData);
      } else {
        currentPayments.push(newInvoiceData);
      }
      form.setFieldValue('payments', currentPayments);
      setIsModalOpen(false);
      setEditingInvoiceIndex(null);

      submit();
    },
    [form, editingInvoiceIndex, submit],
  );

  const openEditModal = useCallback((invoiceIndex: number) => {
    setEditingInvoiceIndex(invoiceIndex);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form, projectId]);

  if (!finance) return <Spin />;

  const initialFormValues = {
    ...finance,
    payments: convertPaymentsDateRange(finance.payments),
  };

  if (ability.can('update', 'Invoice')) {
    return (
      <>
        <Form
          layout="vertical"
          onFinish={submit}
          autoComplete="off"
          initialValues={initialFormValues}
          onValuesChange={updateTotalAfterPaymentChange}
          form={form}
          className="payments"
        >
          <Row className="header">
            <Col span={3}>
              <p>Invoice number</p>
            </Col>

            <Col span={3}>
              <p>Value</p>
            </Col>

            <Col span={3}>
              <p>Due</p>
            </Col>

            <Col span={3}>
              <p>Estimated pay date</p>
            </Col>

            <Col span={3}>
              <p>Sent date</p>
            </Col>

            <Col span={3}>
              <p>Paid date</p>
            </Col>

            <Col span={2}>
              <p>Status</p>
            </Col>

            <Col span={2}>
              <p>Is Active?</p>
            </Col>

            <Col span={2}>
              <p>Billable rate</p>
              <MoneyInput value={billableRate} onChange={handleBillableRateChange} disabled={!ability.can('update', 'Invoice', 'value')} />
            </Col>
          </Row>

          <Form.List name="payments">
            {(fields: any, { add, remove }) => (
              <>
                {fields.map((field: any) => {
                  const { statusColor, statusText } = getInvoiceStatus(
                    finance.payments[field.name],
                    field.name,
                    payDateFullness,
                    estimatedPayDates,
                    isActiveInvoices,
                  );

                  const estimatedPayDate = estimatedPayDates.length ? estimatedPayDates[field.name] : finance.payments[field.name]?.estimatedPayDate;
                  const isPaid =
                    payDateFullness?.length && field.name !== undefined ? payDateFullness[field.name] : finance.payments[field.name]?.paidDate;
                  const items: MenuProps['items'] = [
                    {
                      label: (
                        <Popconfirm
                          title="Are you sure to delete this payment?"
                          onConfirm={() => {
                            remove(field.name);
                            submit();
                          }}
                          okText="Yes"
                          cancelText="No"
                          placement="topRight"
                        >
                          <div>
                            <DeleteOutlined /> Delete
                          </div>
                        </Popconfirm>
                      ),
                      key: '0',
                    },
                  ];

                  return (
                    <div key={field.key}>
                      <Collapse
                        ghost
                        collapsible="icon"
                        expandIcon={({ isActive }) => (isActive ? <DownOutlined /> : <RightOutlined />)}
                        expandIconPosition="end"
                        className={`invoice-item ${statusColor}`}
                      >
                        <CollapsePanel
                          header={
                            <>
                              {ability.can('delete', 'Invoice') && (
                                <Dropdown menu={{ items }} trigger={['click']} className="invoice-menu" placement="bottomRight">
                                  <Button type="text" className="dots-menu">
                                    <EllipsisOutlined />
                                  </Button>
                                </Dropdown>
                              )}

                              <Row gutter={16}>
                                <Col span={3}>
                                  <Form.Item {...field} name={[field.name, 'invoiceNumber']}>
                                    <Input disabled={!ability.can('update', 'Invoice', 'invoiceNumber')} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item {...field} name={[field.name, 'value']} rules={[{ required: true, message: 'Missing value' }]}>
                                    <MoneyInput disabled={!ability.can('update', 'Invoice', 'value')} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item {...field} name={[field.name, 'due']}>
                                    <Input disabled={!ability.can('update', 'Invoice', 'due')} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'estimatedPayDate']}
                                    validateStatus={estimatedPayDate ? '' : 'error'}
                                    help={estimatedPayDate ? '' : 'Please add estimated pay date!'}
                                  >
                                    <DatePicker format={datePickerFormat} disabled={!ability.can('update', 'Invoice', 'estimatedPayDate')} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item {...field} name={[field.name, 'sentDate']}>
                                    <DatePicker format={datePickerFormat} disabled={!ability.can('update', 'Invoice', 'sentDate')} />
                                  </Form.Item>
                                </Col>

                                <Col span={3}>
                                  <Form.Item {...field} name={[field.name, 'paidDate']}>
                                    <DatePicker format={datePickerFormat} disabled={!ability.can('update', 'Invoice', 'paidDate')} />
                                  </Form.Item>
                                </Col>

                                <Col span={2}>
                                  <Form.Item {...field} name={[field.name, 'paid']} disabled={!ability.can('update', 'Invoice', 'paid')}>
                                    <div className={`status-tag ${isPaid ? 'paid-invoice' : statusColor}`}>{isPaid ? 'Paid' : statusText}</div>
                                  </Form.Item>
                                </Col>

                                <Col span={1}>
                                  <Form.Item {...field} name={[field.name, 'isActive']} valuePropName="checked">
                                    <Switch checkedChildren="Active" unCheckedChildren="Draft" disabled={!ability.can('update', 'Invoice', 'paid')} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          }
                          key={field.key}
                        >
                          <Row gutter={8}>
                            <Col span={24}>
                              <Row gutter={16}>
                                <Col span={12}>
                                  <Form.Item {...field} label="Internal note" name={[field.name, 'description']}>
                                    <TextArea rows={4} disabled={!ability.can('update', 'Invoice', 'description')} />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item {...field} label="Client note" name={[field.name, 'clientNote']}>
                                    <TextArea rows={4} disabled={!ability.can('update', 'Invoice', 'clientNote')} />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item {...field} label="Internal link" name={[field.name, 'invoiceLink']}>
                                    <Input
                                      addonAfter={
                                        <a href={(finance.payments[field.name] || {}).invoiceLink} target="_blank" rel="noreferrer">
                                          <Button type="text" size="small">
                                            Go
                                          </Button>
                                        </a>
                                      }
                                      disabled={!ability.can('update', 'Invoice', 'invoiceLink')}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={[16, 16]}>
                                <Col span={24}>
                                  <Form.Item {...field} label="Client link" name={[field.name, 'clientInvoiceLink']}>
                                    <Input
                                      addonAfter={
                                        <a href={(finance.payments[field.name] || {}).clientInvoiceLink} target="_blank" rel="noreferrer">
                                          <Button type="text" size="small">
                                            Go
                                          </Button>
                                        </a>
                                      }
                                      disabled={!ability.can('update', 'Invoice', 'clientInvoiceLink')}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              {form.getFieldValue(['payments', field.name, 'details']) && (
                                <Row style={{ marginTop: '1rem' }} gutter={16}>
                                  <Col span={24} className="invoice-table">
                                    <Row>
                                      {ability.can('create', 'Invoice') && (
                                        <Form.Item>
                                          <Button
                                            style={{ padding: '0 0.5rem' }}
                                            type="dashed"
                                            onClick={() => openEditModal(field.name)}
                                            block
                                            icon={<EditOutlined />}
                                          ></Button>
                                        </Form.Item>
                                      )}

                                      <Form.Item name={[field.name, 'dateRange']}>
                                        <DatePicker.RangePicker allowClear={false} inputReadOnly open={false} />
                                      </Form.Item>
                                    </Row>

                                    <Form.Item {...field} name={[field.name, 'details']}>
                                      <InvoiceTable details={form.getFieldValue(['payments', field.name, 'details'])} />
                                    </Form.Item>

                                    <Form.Item {...field} name={[field.name, 'details']}>
                                      <InvoiceTable isClientView details={form.getFieldValue(['payments', field.name, 'details'])} />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </Row>
                        </CollapsePanel>
                      </Collapse>
                    </div>
                  );
                })}

                <Row style={{ marginTop: '1rem' }} gutter={[16, 16]}>
                  <Col span={5}>
                    {ability.can('create', 'Invoice') && (
                      <Form.Item>
                        <Button type="dashed" onClick={() => setIsModalOpen(true)} block icon={<PlusOutlined />}>
                          Add from timesheet
                        </Button>
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={18}>
                    {ability.can('create', 'Invoice') && (
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          New invoice
                        </Button>
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={2} style={{ textAlign: 'right' }}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>

        <Modal
          title={`${editingInvoiceIndex ? 'Edit' : 'Create'} invoice from timesheet`}
          open={isModalOpen}
          width="1200px"
          onCancel={() => {
            setIsModalOpen(false);
            setEditingInvoiceIndex(null);
          }}
          destroyOnClose
          footer={null}
        >
          <InvoiceCreator
            projectId={projectId}
            billableRate={finance.billableRate}
            onInvoiceCreated={data => addInvoice(data)}
            editingInvoice={editingInvoiceIndex ? form.getFieldValue('payments')[editingInvoiceIndex] : undefined}
          />
        </Modal>
      </>
    );
  }

  if (ability.can('view', 'Invoice')) {
    return (
      <Table
        dataSource={finance.payments}
        size="small"
        key={new Date().toDateString()}
        pagination={false}
        style={{ marginBottom: '16px' }}
        rowClassName={record => getInvoiceStatus(record).statusColor}
      >
        <Column title="Invoice number" width="100px" render={(p: Payment) => p.invoiceNumber} />
        <Column title="Value" width="100px" render={(p: Payment) => formatCurrency(p.value)} />
        <Column title="Due" width="100px" render={(p: Payment) => p.due || ''} />
        <Column
          title="Estimated pay date"
          width="100px"
          render={(p: Payment) => (p.estimatedPayDate ? moment(p.estimatedPayDate).format(datePickerFormat) : '')}
        />
        <Column title="Sent date" width="100px" render={(p: Payment) => (p.sentDate ? moment(p.sentDate).format(datePickerFormat) : '')} />
        <Column title="Paid date" width="100px" render={(p: Payment) => (p.paidDate ? moment(p.paidDate).format(datePickerFormat) : '')} />
        <Column
          title="Client invoice link"
          width="150px"
          render={(p: Payment) =>
            p.clientInvoiceLink && (
              <a href={p.clientInvoiceLink} target="_blank" rel="noreferrer">
                Open in new window
              </a>
            )
          }
        />
        <Column title="Internal note" width="100px" render={(p: Payment) => p.description || ''} ellipsis />
        <Column title="Client note" width="100px" render={(p: Payment) => p.clientNote || ''} ellipsis />
        <Column
          title="Status"
          width="80px"
          render={(p: Payment) => <div className={`status-tag ${getInvoiceStatus(p).statusColor}`}>{getInvoiceStatus(p).statusText}</div>}
        />
      </Table>
    );
  }

  return null;
};

export default ProjectFinances;
