import { useCallback, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, message, Radio, Row, Space, Table } from 'antd';
import moment from 'moment';
import { useImmer } from 'use-immer';
import { TimesheetSummary, useProjectsExtendedTimesheetSummary } from '../../dal';
import { displayDurationFromMinutes, formatCurrency, nullableDataSorter } from '../../common/utils';
import MoneyInput from '../shared/MoneyInput';
import HoursInput from '../Timesheet/HoursInput';
import { calculateGroupSum, convertDateRange, formInvoiceFromTimesheetData } from '../../utils';
import { BillableInfo, GroupByType, Payment } from '../../entities';

type Props = {
  projectId: string;
  billableRate: number;
  onInvoiceCreated: (data: any) => void;
  editingInvoice?: Payment;
};

const InvoiceCreator = ({ projectId, billableRate, onInvoiceCreated, editingInvoice }: Props) => {
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const { timesheets } = useProjectsExtendedTimesheetSummary(projectId, dateRange[0], dateRange[1]);
  const [customValues, setCustomValues] = useImmer<Record<string, BillableInfo>>({});
  const [globalBillableRate, setGlobalBillableRate] = useState<number>(billableRate);
  const [groupingOption, setGroupingOption] = useState<GroupByType>('');
  const [storedTimesheets, setStoredTimesheets] = useState<TimesheetSummary[] | null>(timesheets);

  useEffect(() => {
    if (editingInvoice) {
      setDateRange(convertDateRange(editingInvoice.dateRange));
    }

    if (JSON.stringify(timesheets) !== JSON.stringify(storedTimesheets)) {
      setStoredTimesheets(timesheets);

      const updatedCustomValues = editingInvoice
        ? editingInvoice.details.reduce((acc, detail) => {
            acc[detail.key] = {
              billableHours: detail.billableHours,
              billableRate: detail.billableRate,
            };
            return acc;
          }, {})
        : timesheets.reduce((acc, t) => {
            const billableHours = t.totalMinutes / 60;

            if (!acc[t.key]) {
              acc[t.key] = {
                billableHours: billableHours,
                billableRate: globalBillableRate,
              };
            }
            return acc;
          }, {});

      setCustomValues(draft => {
        if (JSON.stringify(draft) !== JSON.stringify(updatedCustomValues)) {
          Object.assign(draft, updatedCustomValues);
        }
      });
    }
  }, [editingInvoice, globalBillableRate, setCustomValues, storedTimesheets, timesheets]);

  const updateCustomValue = useCallback(
    (key: string, field: string, value: any) => {
      setCustomValues(draft => {
        draft[key] ??= { billableHours: 0, billableRate: globalBillableRate, billableTotal: 0 };
        draft[key][field] = value;
      });
    },
    [globalBillableRate, setCustomValues],
  );

  const handleGlobalBillableRateChange = useCallback(
    value => {
      setGlobalBillableRate(value);

      setCustomValues(draft => {
        Object.keys(draft).forEach(key => {
          draft[key]['billableRate'] = value;
        });
      });
    },
    [setCustomValues],
  );

  const submitInvoice = useCallback(() => {
    if (dateRange[0] === null && dateRange[1] === null) {
      message.error('No date range added');
      return;
    }

    const invoiceValue = Object.values(customValues)
      .map((cv: any) => (cv.billableHours ?? 0) * (cv.billableRate ?? 0))
      .reduce((prev, curr) => prev + curr, 0);

    const actualValue = timesheets.map(cv => +cv.totalCost).reduce((prev, curr) => prev + curr, 0);

    const summary = timesheets.reduce(
      (acc, { key, totalMinutes, totalCost }) => {
        acc.summaryBillableSpent += customValues[key]?.billableHours * customValues[key]?.billableRate || 0;
        acc.summaryTotalMinutes += +totalMinutes || 0;
        acc.summaryBillableHours += customValues[key]?.billableHours || 0;
        acc.summaryRealSpent += +totalCost || 0;
        return acc;
      },
      { summaryBillableSpent: 0, summaryTotalMinutes: 0, summaryBillableHours: 0, summaryRealSpent: 0 },
    );

    const { summaryBillableSpent, summaryTotalMinutes, summaryBillableHours, summaryRealSpent } = summary;

    onInvoiceCreated({
      value: Math.round(invoiceValue * 100) / 100,
      dateRange,
      markupPercentage: ((1 - actualValue / invoiceValue) * 100).toFixed(2),
      summaryBillableHours,
      summaryBillableSpent,
      summaryRealSpent,
      summaryTotalMinutes,
      details: timesheets.map(t => ({
        ...t,
        role: t.category,
        phase: t.phaseName || '',
        billableHours: customValues[t.key].billableHours,
        billableRate: customValues[t.key].billableRate,
        billableTotal: +(customValues[t.key].billableRate * customValues[t.key].billableHours).toFixed(2),
      })),
    });
  }, [customValues, dateRange, onInvoiceCreated, timesheets]);

  const summary = useCallback(
    pageData => {
      let summaryBillableSpent = 0;
      let summaryTotalMinutes = 0;
      let summaryBillableHours = 0;
      let summaryRealSpent = 0;

      pageData
        .filter(pd => !pd.isGroup)
        .forEach(({ key, totalMinutes, totalCost }) => {
          summaryBillableSpent += customValues[key]?.billableHours * customValues[key]?.billableRate;
          summaryTotalMinutes += +totalMinutes;
          summaryBillableHours += customValues[key]?.billableHours;
          summaryRealSpent += +totalCost;
        });

      return (
        <>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 3 : 4}></Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {displayDurationFromMinutes(summaryTotalMinutes)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {formatCurrency(summaryRealSpent)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <Input value={displayDurationFromMinutes(Math.round(summaryBillableHours * 60))} bordered={false} readOnly={true} />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5} align="right">
              {formatCurrency(summaryBillableSpent)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 7 : 8} align="right">
              Markup
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {summaryBillableSpent > 0 ? ((1 - summaryRealSpent / summaryBillableSpent) * 100).toFixed(2) + '%' : ''}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      );
    },
    [customValues, groupingOption],
  );

  const dataSource = formInvoiceFromTimesheetData(timesheets, groupingOption);

  const rowClassName = record => {
    return record.isGroup ? 'role-group-row' : '';
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={values => setDateRange(values || [null, null])}
          renderExtraFooter={() => (
            <Row justify="space-between">
              <Col>
                <Button type="link" onClick={() => setDateRange([moment().startOf('month'), moment().endOf('month')])}>
                  This month
                </Button>
                <Button
                  type="link"
                  onClick={() => setDateRange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')])}
                >
                  Previous month
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().subtract(30, 'days'), moment()])}>
                  Last 30 days
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().startOf('year'), moment().endOf('year')])}>
                  This year
                </Button>
              </Col>
              <Col>
                <Button type="link" onClick={() => setDateRange([null, null])}>
                  Clear
                </Button>
              </Col>
            </Row>
          )}
        />

        <Radio.Group
          options={[
            { label: 'Ungrouped', value: '' },
            { label: 'Group by role', value: 'role' },
            { label: 'Group by phase', value: 'phase' },
          ]}
          onChange={e => setGroupingOption(e.target.value)}
          value={groupingOption}
          optionType="button"
        />
      </Row>

      <Table dataSource={dataSource} pagination={false} size="small" summary={summary} rowClassName={rowClassName}>
        <Table.Column
          title="Resource"
          dataIndex="userName"
          render={(value, record: TimesheetSummary) => (record.isGroup ? record.key : value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.userName, b.userName))}
        />
        <Table.Column
          title={groupingOption === 'role' ? 'Phase' : 'Role'}
          dataIndex={groupingOption === 'role' ? 'phaseName' : 'category'}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
        />
        {!groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}
        <Table.Column
          title="Hourly rate"
          width="100px"
          dataIndex="ratePerHour"
          render={(value, record: TimesheetSummary) => !record.isGroup && formatCurrency(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          align="right"
        />
        <Table.Column
          title="Real hours"
          width="100px"
          dataIndex="totalMinutes"
          render={(value, record: TimesheetSummary) => !record.isGroup && displayDurationFromMinutes(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
          align="right"
        />
        <Table.Column
          title="Real spend"
          width="100px"
          dataIndex="totalCost"
          render={(value, record: TimesheetSummary) => !record.isGroup && formatCurrency(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost, b.totalCost))}
          align="right"
        />
        <Table.Column
          title="Billable hours"
          width="100px"
          dataIndex="key"
          align="right"
          render={(key, record: TimesheetSummary) =>
            record.isGroup ? (
              <HoursInput
                readOnly
                value={calculateGroupSum(customValues, key, 'hours')}
                onChange={(_, number) => updateCustomValue(key, 'billableHours', number)}
              />
            ) : (
              <HoursInput value={customValues[key]?.billableHours} onChange={(_, number) => updateCustomValue(key, 'billableHours', number)} />
            )
          }
        />
        <Table.Column
          title={
            <div>
              <div>Billable rate</div>

              <MoneyInput value={globalBillableRate} onChange={handleGlobalBillableRateChange} />
            </div>
          }
          width="100px"
          dataIndex="key"
          align="right"
          render={(key, record: TimesheetSummary) =>
            !record.isGroup && (
              <MoneyInput value={customValues[key]?.billableRate} onChange={value => updateCustomValue(key, 'billableRate', value)} />
            )
          }
        />
        <Table.Column
          title="Billable spend"
          width="100px"
          dataIndex="key"
          render={(key, record: TimesheetSummary) =>
            record.isGroup
              ? formatCurrency(calculateGroupSum(customValues, key, 'spend'))
              : formatCurrency(customValues[key]?.billableHours * customValues[key]?.billableRate)
          }
          align="right"
        />
      </Table>
      <Button type="primary" style={{ width: '100%' }} onClick={submitInvoice}>
        {`${editingInvoice ? 'Edit' : 'Create'} invoice`}
      </Button>
    </Space>
  );
};

export default InvoiceCreator;
