import { ReactNode } from 'react';
import moment from 'moment';
import { FIGMA_URL, GOOGLE_DOCS_URL, GOOGLE_SHEETS_URL, PANDA_DOC_URL, XERO_URL } from './constants';
import { BillableInfo, GroupByType, Payment } from '../entities';
import { ReactComponent as IconFigma } from '../images/icons/icon-figma.svg';
import { ReactComponent as IconDocs } from '../images/icons/icon-google-docs.svg';
import { ReactComponent as IconSheets } from '../images/icons/icon-google-sheets.svg';
import { ReactComponent as IconXero } from '../images/icons/icon-xero.svg';
import { ReactComponent as IconPandadoc } from '../images/icons/icon-pandadoc.svg';
import { ReactComponent as IconFile } from '../images/icons/file-gray.svg';
import { TimesheetSummary } from '../dal';

const iconMap: Record<string, ReactNode> = {
  [FIGMA_URL]: <IconFigma />,
  [GOOGLE_DOCS_URL]: <IconDocs />,
  [GOOGLE_SHEETS_URL]: <IconSheets />,
  [XERO_URL]: <IconXero />,
  [PANDA_DOC_URL]: <IconPandadoc />,
};

export const getIcon = (url: string): ReactNode => {
  const matchingKey = Object.keys(iconMap).find(key => url.includes(key));
  return matchingKey ? iconMap[matchingKey] : <IconFile />;
};

export const getInitials = (name: string) => {
  const words = name.trim().split(' ');
  const initials = words.map((word, index) => (index === 0 || index === words.length - 1 ? word.charAt(0).toUpperCase() : '')).join('');
  return initials;
};

export const convertDateRange = dateRange => {
  if (dateRange && Array.isArray(dateRange)) {
    return dateRange.map(date => moment(date));
  }
  return [];
};

export const convertPaymentsDateRange = payments => {
  return payments.map(payment => {
    if (payment.dateRange) {
      return {
        ...payment,
        dateRange: convertDateRange(payment.dateRange),
      };
    }
    return payment;
  });
};

export const getInvoiceStatus = (
  invoice: Payment,
  fieldKey?: number,
  payDateFullness?: boolean[],
  estimatedPayDates?: (moment.Moment | Date | null | undefined)[],
  isActiveInvoices?: boolean[],
) => {
  const isActive = isActiveInvoices?.length && fieldKey !== undefined ? isActiveInvoices[fieldKey] : invoice?.isActive;
  const isPaid = payDateFullness?.length && fieldKey !== undefined ? payDateFullness[fieldKey] : invoice?.paidDate;

  if (!isActive) {
    return { statusColor: 'draft-invoice', statusText: 'Draft' };
  }

  if (isPaid) {
    return { statusColor: 'paid-invoice', statusText: 'Paid' };
  }

  const estimatedPayDate = moment(
    estimatedPayDates?.length && fieldKey !== undefined ? estimatedPayDates[fieldKey] : invoice?.estimatedPayDate,
  ).toDate();

  const isOverdue = estimatedPayDate.getTime() < Date.now();

  return {
    statusColor: isOverdue ? 'overdue-invoice' : 'active-invoice',
    statusText: isOverdue ? 'Overdue' : 'Active',
  };
};

export const deepMerge = (oldData, newData) => {
  for (const key in newData) {
    if (typeof newData[key] === 'object' && !Array.isArray(newData[key])) {
      if (!oldData[key]) {
        oldData[key] = {};
      }
      deepMerge(oldData[key], newData[key]);
    } else {
      oldData[key] = newData[key];
    }
  }
  return oldData;
};

const groupData = (timesheets: TimesheetSummary[], groupingOption: GroupByType) =>
  timesheets.reduce((acc, timesheet) => {
    const key = groupingOption === 'role' ? timesheet.category : timesheet.phaseName || 'No phase';

    if (!acc[key]) {
      acc[key] = {
        [groupingOption]: key,
        children: [],
        billableHours: 0,
        billableTotal: 0,
        billableRate: 0,
      };
    }

    acc[key].children.push(timesheet);
    acc[key].billableHours += timesheet.billableHours;
    acc[key].billableTotal += timesheet.billableTotal;
    acc[key].billableRate = acc[key].billableTotal / acc[key].billableHours;

    return acc;
  }, {});

export const formInvoiceFromTimesheetData = (timesheets: TimesheetSummary[], groupingOption: GroupByType) => {
  if (!groupingOption) {
    return timesheets;
  }

  const groupedData = groupData(timesheets, groupingOption);

  const formedData: TimesheetSummary[] = Object.values(groupedData).flatMap((group: any) => {
    const key = groupingOption === 'role' ? group.role : group.phase || 'No phase';

    return [
      {
        key,
        billableHours: group.billableHours,
        billableRate: group.billableRate,
        billableTotal: group.billableTotal,
        isGroup: true,
      },
      ...group.children,
    ];
  });

  return formedData;
};

export const convertToTitleCase = (str: string) => {
  if (!str) {
    return '';
  }

  return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
};

export const calculateGroupSum = (customValues: Record<string, BillableInfo>, groupKey: string, type: 'hours' | 'spend') => {
  let sum = 0;

  Object.entries(customValues).forEach(([key, value]) => {
    if (key.includes(groupKey)) {
      if (type === 'hours') {
        sum += value.billableHours;
      } else if (type === 'spend') {
        sum += value.billableHours * value.billableRate;
      }
    }
  });

  return sum;
};
