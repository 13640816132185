import { Table, Typography } from 'antd';

const Summary = (data: readonly any[]) => {
  let totalAmount = 0;
  let totalHours = 0;

  data.forEach(({ amount, hours }) => {
    totalAmount += +amount;
    totalHours += +hours;
  });

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} align="right" colSpan={3}>
          Total
        </Table.Summary.Cell>

        <Table.Summary.Cell index={1} align="right">
          <Typography.Text>{totalHours.toFixed(2)}</Typography.Text>
        </Table.Summary.Cell>

        <Table.Summary.Cell index={2} align="right">
          <Typography.Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount || 0)}</Typography.Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
export default Summary;
