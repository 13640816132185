import { Radio, Space, Table } from 'antd';
import { displayDurationFromMinutes, displayHoursFromNumber, formatCurrency, nullableDataSorter } from '../../common/utils';
import { formInvoiceFromTimesheetData } from '../../utils';
import { useState } from 'react';
import { GroupByType } from '../../entities';
import { TimesheetSummary } from '../../dal';

type Props = {
  details: TimesheetSummary[];
  isClientView?: boolean;
};

const InvoiceTable = ({ details, isClientView = false }: Props) => {
  const [groupingOption, setGroupingOption] = useState<GroupByType>(isClientView ? 'role' : '');

  const dataSource = isClientView
    ? formInvoiceFromTimesheetData(details, groupingOption).filter(pd => pd.isGroup && pd.billableTotal)
    : formInvoiceFromTimesheetData(details, groupingOption);

  const summary = pageData => {
    let summaryBillableSpent = 0;
    let summaryTotalMinutes = 0;
    let summaryBillableHours = 0;
    let summaryRealSpent = 0;
    let summaryBillableRate = 0;

    pageData.forEach(({ totalMinutes, totalCost, billableHours, billableRate, isGroup }) => {
      if (isClientView && isGroup) {
        summaryBillableSpent += billableHours * billableRate;
        summaryBillableHours += billableHours;
        summaryBillableRate = summaryBillableSpent / summaryBillableHours;
      } else if (!isClientView && !isGroup) {
        summaryBillableSpent += billableHours * billableRate;
        summaryTotalMinutes += totalMinutes;
        summaryBillableHours += billableHours;
        summaryRealSpent += totalCost;
        summaryBillableRate = summaryBillableSpent / summaryBillableHours;
      }
    });

    return isClientView ? (
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>Total Bill</Table.Summary.Cell>
        <Table.Summary.Cell index={1} align="right">
          {displayDurationFromMinutes(Math.round(summaryBillableHours * 60))}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right">
          {formatCurrency(summaryBillableRate)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3} align="right">
          {formatCurrency(summaryBillableSpent)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ) : (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 3 : 4}></Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {displayDurationFromMinutes(summaryTotalMinutes)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align="right">
            {formatCurrency(summaryRealSpent)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            {displayDurationFromMinutes(Math.round(summaryBillableHours * 60))}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align="right">
            {formatCurrency(summaryBillableRate)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} align="right">
            {formatCurrency(summaryBillableSpent)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={groupingOption ? 7 : 8} align="right">
            Markup
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {summaryBillableSpent > 0 ? ((1 - summaryRealSpent / summaryBillableSpent) * 100).toFixed(2) + '%' : ''}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const rowClassName = record => (!isClientView && record.isGroup ? 'role-group-row' : '');

  const baseOptions = [
    { label: 'Group by role', value: 'role' },
    { label: 'Group by phase', value: 'phase' },
  ];

  const options = isClientView ? baseOptions : [{ label: 'Ungrouped', value: '' }, ...baseOptions];

  return (
    <Space direction="vertical" style={{ width: '100%', marginTop: '1rem' }}>
      <Radio.Group options={options} onChange={e => setGroupingOption(e.target.value)} value={groupingOption} optionType="button" />

      <Table dataSource={dataSource} pagination={false} size="small" summary={summary} rowKey="key" rowClassName={rowClassName}>
        <Table.Column
          title="Resource"
          dataIndex="userName"
          render={(value, record: TimesheetSummary) => (record.isGroup ? record.key : value)}
          sorter={
            (!groupingOption || isClientView) && ((a, b) => nullableDataSorter(isClientView ? a.key : a.userName, isClientView ? b.key : b.userName))
          }
        />
        {!isClientView && (
          <Table.Column
            title={groupingOption === 'role' ? 'Phase' : 'Role'}
            dataIndex={groupingOption === 'role' ? 'phaseName' : 'category'}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
          />
        )}

        {!isClientView && !groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}

        {!isClientView && (
          <Table.Column
            title="Hourly rate"
            dataIndex="ratePerHour"
            align="right"
            render={(value, record: TimesheetSummary) => !record.isGroup && formatCurrency(value)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          />
        )}
        {!isClientView && (
          <Table.Column
            title="Real hours"
            dataIndex="totalMinutes"
            render={(value, record: TimesheetSummary) => !record.isGroup && displayDurationFromMinutes(value)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
            align="right"
          />
        )}
        {!isClientView && (
          <Table.Column
            title="Real spend"
            dataIndex="totalCost"
            align="right"
            render={(value, record: TimesheetSummary) => !record.isGroup && formatCurrency(value)}
            sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost, b.totalCost))}
          />
        )}
        <Table.Column
          title="Billable hours"
          dataIndex="billableHours"
          align="right"
          render={displayHoursFromNumber}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableHours, b.billableHours))
          }
        />
        <Table.Column
          title="Billable rate"
          dataIndex="billableRate"
          align="right"
          render={formatCurrency}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableRate, b.billableRate))
          }
        />
        <Table.Column
          title="Billable spend"
          dataIndex="billableTotal"
          align="right"
          render={formatCurrency}
          sorter={
            (!groupingOption || isClientView) && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.billableTotal, b.billableTotal))
          }
        />
      </Table>
    </Space>
  );
};

export default InvoiceTable;
