import React, { useState } from 'react';
import { Input, Card, Button, Typography, Space, Row, Col } from 'antd';
import { EditOutlined, SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { BillingAddress } from '../../entities';

const { Text } = Typography;

type Props = {
  billingAddress: BillingAddress;
  onSave: (billingAddress: BillingAddress) => void;
};

const fields = [
  { label: 'Address', name: 'address' },
  { label: 'Apartment', name: 'apartment' },
  { label: 'Zip', name: 'zip' },
  { label: 'Phone', name: 'phone' },
];

const ClientBillingAddress = ({ billingAddress, onSave }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState(billingAddress);

  const handleSave = () => {
    onSave(formValues);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormValues(billingAddress);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Card
      title="Billing Address"
      size="small"
      extra={
        isEditing ? (
          <Space>
            <Button icon={<SaveOutlined />} size="small" onClick={handleSave}>
              Save
            </Button>
            <Button icon={<CloseCircleOutlined />} size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Space>
        ) : (
          <Button icon={<EditOutlined />} size="small" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {fields.map(({ label, name }) => (
          <Row key={name} gutter={[8, 16]} align="middle">
            <Col span={6}>
              <Text strong>{label}:</Text>
            </Col>
            <Col span={18}>
              {isEditing ? <Input name={name} value={formValues[name]} onChange={handleChange} /> : <Text>{formValues[name] ?? 'no data'}</Text>}
            </Col>
          </Row>
        ))}
      </Space>
    </Card>
  );
};

export default ClientBillingAddress;
