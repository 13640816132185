import React from 'react';

import { Divider, PageHeader } from 'antd';

import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import ChannelMembersModal from '../channelMembersModal';
import UserFinder from '../userFinder';
import { URL_PARAMS } from '../../../constants';
import ReplyForm from '../Editor';
import { User } from '../../../entities';
import { ChatChannel, UserChannel } from '../../../entities/chat';
import Messages from '../Messages';
import { isMemeberOfChat } from '../../../common/utils';
import ChannelEditModal from '../channelEditModal';
import { useLocation } from 'react-router-dom';
import { useChatApi } from '../../../../api/useChatApi';

interface Props {
  handleChannelListOpen;
  renameChannel;
  channel: ChatChannel | any;
  messages;
  session?: User;
  chatChannels: ChatChannel[];
  activeChannelId;
  users;
  replyTo;
  setReplyTo;
  retrieveMessages;
  isAllowToCommunicate;
  loadUsers;
  chatId;
  searchParams;
  isCreatingNewChannel: boolean;
  newChannelMembers: string[];
  onAddUserToChannel;
  isAbleToRemoveUser?: boolean;
  type: string;
  userWithWhoChat?: UserChannel;
  handleChannel: (key: string, isRefresh?: boolean, isRedirect?: boolean) => void;
  setIsCreatingNewChannel?: (state: boolean) => void;
  createNewChannel?: (values: { name: string; members: User[] }) => void;
  chatWhereUserMember: ChatChannel[];
  controlFunctions: any;
  idToCheck: string;
}

const ChatArea: React.FunctionComponent<Props> = ({
  handleChannelListOpen,
  renameChannel,
  channel,
  messages,
  session,
  chatChannels,
  activeChannelId,
  users,
  replyTo,
  setReplyTo,
  retrieveMessages,
  isAllowToCommunicate,
  loadUsers,
  chatId,
  searchParams,
  isCreatingNewChannel,
  onAddUserToChannel,
  newChannelMembers,
  isAbleToRemoveUser,
  handleChannel,
  setIsCreatingNewChannel,
  type,
  userWithWhoChat,
  createNewChannel,
  controlFunctions,
  chatWhereUserMember,
  idToCheck,
}) => {
  function handleSendMessage(data: any) {
    onAddUserToChannel();
    setIsCreatingNewChannel && setIsCreatingNewChannel(false);

    if (data.error && handleChannel) {
      handleChannel(data.id);
    }

    if (handleChannel) {
      handleChannel(data.data.channel.id, false, true);
    }
  }

  const [isMembersModalOpen, setIsMembersModalOpen] = React.useState(false);
  const [isChannelEditModalOpen, setIsChannelEditModalOpen] = React.useState(false);
  const location = useLocation();
  const chatApi = useChatApi();

  function handleMembersClick() {
    setIsMembersModalOpen(true);
  }

  function handleChannelEditClick() {
    setIsChannelEditModalOpen(true);
  }

  function getChannelName(): string {
    if (newChannelMembers?.length === 1) {
      return newChannelMembers[0].split('/')[0];
    } else if (newChannelMembers?.length > 1) {
      return `${newChannelMembers[0].split('/')[0]}, ${newChannelMembers.length} others`;
    }
    return searchParams['channel-name'] || searchParams[URL_PARAMS.VIEW] + '';
  }

  const [isSameChannelExist, setIsSameChannelExist] = React.useState<ChatChannel | undefined>();

  React.useEffect(() => {
    setIsSameChannelExist(undefined);
    onAddUserToChannel([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function handleAddNewMembers() {
    if (channel) {
      if (channel.isNameEdited) {
        chatApi.addMembers(channel.id, newChannelMembers.map(user => user.split('/')[1]));
      } else {
        const channelMembers = channel.users.reduce(
          (pV, cV) => (cV.user.id !== session?.id ? [...pV, cV.user.name + '/' + cV.user.id] : pV),
          newChannelMembers,
        );

        const isChannelExist = chatChannels.find(channel => {
          return !channel.isNameEdited && channel.users.length === channelMembers.length + 1 && isMemeberOfChat(channel, channelMembers);
        });

        if (isChannelExist) {
          handleChannel(isChannelExist.id, true);
        } else if (createNewChannel) {
          const membersFormated: User[] = channelMembers.map(member => users.find(user => user.id === member.split('/')[1]));
          createNewChannel({ name: '', members: membersFormated });
        }
      }
    }
  }

  React.useEffect(() => {
    if (newChannelMembers && newChannelMembers.length) {
      const result = chatChannels.find(channel => {
        return !channel.isNameEdited && channel.users.length === newChannelMembers.length + 1 && isMemeberOfChat(channel, newChannelMembers);
      });
      if (result && isCreatingNewChannel) {
        retrieveMessages(result.id);
      }
      setIsSameChannelExist(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChannelMembers, chatChannels]);

  const NameChangeRef = React.createRef<HTMLDivElement>();
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  return (
    <div className="bg-white rounded-[8px] h-full">
      <ChannelEditModal
        handleAddNewMembers={handleAddNewMembers}
        onAddUserToChannel={onAddUserToChannel}
        user={session}
        allUsers={users}
        members={channel?.users}
        channelId={channel?.id}
        setIsModalOpen={setIsChannelEditModalOpen}
        isModalOpen={isChannelEditModalOpen}
      />
      <ChannelMembersModal
        isAbleToRemove={isAbleToRemoveUser || false}
        user={session}
        allUsers={users}
        members={channel?.users}
        channelId={channel?.id}
        setIsModalOpen={setIsMembersModalOpen}
        isModalOpen={isMembersModalOpen}
      />
      <PageHeader
        className="chat-header rounded-t-[8px]"
        title={
          <div className="flex items-center">
            <ArrowLeftOutlined className="block md:hidden text-gray-600 ml-2 mr-3 font-bold" onClick={handleChannelListOpen} />
            {isCreatingNewChannel ? (
              <>
                <h3 className="overflow-hidden text-ellipsis m-0 mr-3 font-semibold ">New direct message to: </h3>{' '}
                <UserFinder
                  onChange={onAddUserToChannel}
                  options={users.reduce(
                    (pV, cV) =>
                      cV.id !== session?.id
                        ? [
                            ...pV,
                            {
                              label: (
                                <span key={cV.id}>
                                  <img className="my-[.2rem] w-5 h-5 rounded-full" src={cV.picture} alt="" /> {cV.name}
                                </span>
                              ),
                              value: cV.name + '/' + cV.id,
                            },
                          ]
                        : pV,
                    [],
                  )}
                />
              </>
            ) : (
              <>
                <div
                  tabIndex={1}
                  ref={NameChangeRef}
                  className={` overflow-hidden text-black font-semibold text-lg p-1 px-2 outline-none rounded-lg ${
                    isInEditMode ? ' border-solid !border !border-blue-600' : 'text-ellipsis'
                  }`}
                  suppressContentEditableWarning
                  contentEditable={channel.isNameEdited || channel.users.length > 2}
                  onFocus={e => {
                    if (channel.isNameEdited || channel.users.length > 2) {
                      const range = document.createRange();
                      range.selectNodeContents(e.target);
                      const sel = window.getSelection();
                      sel?.removeAllRanges();
                      sel?.addRange(range);
                      setIsInEditMode(true);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (NameChangeRef.current?.innerText) {
                        renameChannel(NameChangeRef.current?.innerText);
                        NameChangeRef.current?.blur();
                      }
                    }
                  }}
                  onBlur={data => {
                    if (data.relatedTarget?.id !== 'accept_rename' || !data.target.innerText || data.target.innerText.length < 1) {
                      data.target.innerText = userWithWhoChat?.user.name
                        ? userWithWhoChat?.user.name
                        : channel.users
                        ? channel.isNameEdited
                          ? channel.name
                          : channel.users.reduce((pV, cV) => (cV.user.id !== session?.id ? `${pV ? pV + ',' : ''} ${cV.user.name}` : pV), '')
                        : channel.name;
                    } else if (data.target.innerText) {
                      renameChannel(data.target.innerText);
                    }
                    setIsInEditMode(false);
                  }}
                >
                  {userWithWhoChat?.user.name
                    ? userWithWhoChat?.user.name
                    : channel.users
                    ? channel.isNameEdited
                      ? channel.name
                      : channel.users.reduce((pV, cV) => (cV.user.id !== session?.id ? `${pV ? pV + ',' : ''} ${cV.user.name}` : pV), '')
                    : channel.name}
                </div>
                {channel.isNameEdited || channel.users.length > 2 ? (
                  isInEditMode ? (
                    <>
                      <CheckOutlined id="accept_rename" onClick={() => {}} className="ml-1 hover:text-blue-600" />
                      <CloseOutlined
                        id="decline_rename"
                        onClick={() => {
                          if (NameChangeRef.current) {
                            NameChangeRef.current.innerText = userWithWhoChat?.user.name
                              ? userWithWhoChat?.user.name
                              : channel.users
                              ? channel.isNameEdited
                                ? channel.name
                                : channel.users.reduce((pV, cV) => (cV.user.id !== session?.id ? `${pV ? pV + ',' : ''} ${cV.user.name}` : pV), '')
                              : channel.name;
                          }
                        }}
                        className="ml-1 hover:text-blue-600"
                      />
                    </>
                  ) : channel.isNameEdited ? (
                    <EditOutlined
                      className=" hover:text-blue-600 ml-1"
                      onClick={() => {
                        NameChangeRef.current?.focus();
                      }}
                    />
                  ) : (
                    <div
                      className=" text-sm transition-all h-8 w-fit rounded-[6px] backshadow cursor-pointer hover:bg-[#4F67FF] hover:text-white select-none flex justify-center items-center px-2 mr-1"
                      onClick={() => {
                        if (NameChangeRef.current) {
                          NameChangeRef.current.innerText = '';
                          NameChangeRef.current.focus();
                        }
                      }}
                    >
                      Convert to channel
                    </div>
                  )
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        }
        extra={[
          type !== 'nothing' && (
            <div key="no-nothing" className="flex h-9 w-fit rounded-[6px] backshadow select-none overflow-hidden">
              <div
                onClick={handleMembersClick}
                className="transition-all hover:bg-[#4F67FF] two-user w-[57px] h-full flex items-center justify-center cursor-pointer"
              >
                <div className="two-user-icon w-[20px]"></div>
                <div className="ml-2">{channel?.users.length}</div>
              </div>
              <Divider type="vertical" className="!m-0 !h-full" />
              <div
                onClick={channel.type !== 'individual' ? handleChannelEditClick : undefined}
                className={`transition-all hover:bg-[#4F67FF] add-user w-[36px] h-full flex items-center justify-center cursor-pointer ${
                  channel.type === 'individual' && 'hover:bg-[#fc3725] !cursor-not-allowed'
                }`}
              >
                <div className="add-user-icon w-[20px]"></div>
              </div>
            </div>
          ),
          channel && (channel.unread || channel.manualUnread) ? (
            <div
              key="yes-nothing"
              className="transition-all h-9 w-fit rounded-[6px] cursor-pointer hover:bg-[#4F67FF] hover:text-white backshadow select-none overflow-hidden flex justify-center items-center px-2"
              onClick={() => {
                controlFunctions.handleAllRead(messages[channel?.id]);
                controlFunctions.forceUpdate();
              }}
            >
              Mark all as read
            </div>
          ) : null,
        ]}
      />
      {isSameChannelExist && isCreatingNewChannel && newChannelMembers?.length > 0 ? (
        session && <Messages
          user={session}
          chatChannel={isSameChannelExist}
          messages={messages[isSameChannelExist?.id]}
          users={users}
          replyTo={replyTo}
          setReplyTo={setReplyTo}
          loadMessages={(part: number) => {
            retrieveMessages(isSameChannelExist?.id, part, true);
          }}
          controlFunctions={controlFunctions}
          style={{ height: `calc(100vh - ${isAllowToCommunicate !== undefined ? '240px' : '100px'})` }}
          isAllowToCommunicate={isAllowToCommunicate}
          chatWhereUserMember={chatWhereUserMember}
        />
      ) : (
        session && <Messages
          user={session}
          chatChannel={chatChannels[chatChannels.findIndex(c => c.id === activeChannelId)]}
          messages={messages[channel?.id]}
          users={users}
          replyTo={replyTo}
          setReplyTo={setReplyTo}
          loadMessages={(part: number) => {
            retrieveMessages(channel?.id, part, true);
          }}
          controlFunctions={controlFunctions}
          style={{ height: `calc(100vh - ${isAllowToCommunicate !== undefined ? '240px' : '100px'})` }}
          isAllowToCommunicate={isAllowToCommunicate}
          chatWhereUserMember={chatWhereUserMember}
        />
      )}
      {location && isAllowToCommunicate !== undefined && (
        <>
          <ReplyForm
            idToCheck={idToCheck}
            newChannelMembers={newChannelMembers}
            isSameChannelExist={isSameChannelExist}
            isCreatingNewChannel={isCreatingNewChannel}
            handleSendMessage={handleSendMessage}
            disabled={isCreatingNewChannel && newChannelMembers?.length === 0}
            messages={messages[channel?.id]}
            controlFunctions={controlFunctions}
            users={users}
            loadUsers={loadUsers}
            chatId={chatId}
            session={session}
            channel={activeChannelId}
            channelName={getChannelName()}
            replyTo={replyTo}
            setReplyTo={setReplyTo}
          />
        </>
      )}
    </div>
  );
};

export default ChatArea;
