import useSWR from 'swr';
import moment from 'moment';
import { FetchResult, ProjectFinance } from '../entities';

type Result = FetchResult & { finance: ProjectFinance | null };

export function useProjectFinance(id: string): Result {
  const { data, error, mutate } = useSWR(`/projects/${id}/finance`);

  return {
    finance: data?.data && data?.data.budget !== undefined ? setDateType(data?.data) : data?.data, // use setDataType only for finances role
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

function setDateType({
  budget,
  plannedHours,
  desiredBudget,
  averageHourlyRate,
  billableRate,
  payments,
  budgetDetails,
  calculation,
}: ProjectFinance): ProjectFinance {
  const paidTotal = payments.reduce((acc: number, payment: any) => (payment?.paid ? (+payment?.value || 0) + acc : acc), 0);
  return {
    budget,
    plannedHours,
    desiredBudget,
    averageHourlyRate,
    billableRate: Number(billableRate),
    payments: payments.map(p => ({
      ...p,
      estimatedPayDate: p?.estimatedPayDate ? moment(p.estimatedPayDate) : undefined,
      sentDate: p?.sentDate ? moment(p.sentDate) : undefined,
      paidDate: p?.paidDate ? moment(p.paidDate) : undefined,
    })),
    budgetDetails: budgetDetails.map(p => ({ ...p, contractSignedDate: p.contractSignedDate ? moment(p.contractSignedDate) : null })),
    paidTotal,
    remaining: budget - paidTotal,
    calculation,
  };
}
