import useSWR from 'swr';
import { FetchResult, ProjectReportItem } from '../entities';

type Result = FetchResult & { projectReport: ProjectReportItem[]; pagination: any };

export function useTimesheetReportByProjectCode(projectCode: string | null, currentMonth: string | null): Result {
  const { data, error, mutate } = useSWR(() =>
    projectCode !== null ? `/project-finances/timesheet-report-by-project-code?currentMonth=${currentMonth || ''}&projectCode=${projectCode}` : null,
  );

  return {
    projectReport: data?.data,
    pagination: data?.pagination,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}
